import React, {Component} from "react"
import styled, {css} from "styled-components"
import {Row, Col, Container} from 'reactstrap'
import {graphql, StaticQuery} from 'gatsby'
import Layout from "components/Layout/Layout"
import HeadingSpecial from "../components/shared/HeadingSpecial"
import TopBar from "../components/shared/TopBar"
import {media} from "utils/Media"
import iconArrowDown from "images/FaqsPage/icon-arrow-down.svg"
import PreviewModal from '../components/shared/PreviewModal'
import { StaticImage } from "gatsby-plugin-image"

const scrollToElement = require('scroll-to-element')

const FaqContent = styled.div`
    margin-top: 2rem;
    padding-bottom: 1rem;
    
    @media ${media.lg} {
        padding-bottom: 0;
    }
  h3 {
    color: ${props => props.theme.colors.navy};
    text-align: center;
  }

  a {
    font-weight: bold;
    color: ${props => props.theme.colors.navy};
  }
`
const Box = styled.div`
  border: 1px solid ${props => props.theme.colors.blue};
  padding: 1rem;
  position: relative;
  margin: 1rem auto;
  background-color: ${props => props.theme.colors.white};

  h4 {
    color: ${props => props.theme.colors.navy};
  }

  .icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 40px;
    height: 40px;
  }

  .close-to-frozen {
    max-width: 551px;
    margin: 0 auto;
  }

  ${props => props.taxi && css`
    @media ${media.xl} {
      min-height: 540px;
    }
  `} ${props => props.access && css`
    @media ${media.xl} {
      min-height: 414px;
    }
  `} ${props => props.faqs && css`
    padding: 1rem 4rem 1rem 1rem;
    @media ${media.xl} {
      padding: 1rem 3rem 1rem 0.5rem;
    }
  `}

`

const CustomCollapse = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0 auto;

  .customCollapse__item {
    width: 100%;
    padding: 0;

    &-trigger {
      cursor: pointer;
      width: 100%;
    }

    &-wrapper {
      height: 0;
      visibility: hidden;
      overflow: hidden;
      position: absolute;
      left: 0;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      transition: height .3s ease;

      &-inner {
        padding: 1rem;
        margin: 0 auto;
      }
    }

    .arrowDown {
      position: absolute;
      top: 1.75rem;
      right: 0.5rem;
      width: 32px;
      height: 32px;
      transform: translateY(-50%);
      background: url("${iconArrowDown}") no-repeat center center;
      background-size: 100%;
    }

    &.active {
      .customCollapse__item-wrapper {
        visibility: visible;
      }

      .arrowDown {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
`


const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                allPreviewScheduleJson {
                    edges {
                        node {
                            date
                            time
                        }
                    }
                }
                allFaqsJson {
                    edges {
                        node {
                            question
                            answerHTML
                            galaPro
                        }
                    }
                }
               allMobileFaqsJson {
                    edges {
                        node {
                            question
                            answerHTML
                            galaPro
                        }
                    }
                }
                ForegroundBottom: file(relativePath: { eq: "foreground-bottom.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                HeaderImageXs: file(relativePath: { eq: "FaqsPage/bg-faq-header-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                HeaderImageSm: file(relativePath: { eq: "FaqsPage/bg-faq-header-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                HeaderImageXl: file(relativePath: { eq: "FaqsPage/bg-faq-header-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                
                CloseApp: file(relativePath: { eq: "HomePage/close-app-phone.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 551, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <FaqsPage data={data}/>
        )}
    />
)

class FaqsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeId: "",
            contentHeight: 0,
            previewModalOpen: false,
        }
    }
    
    togglePreviewModal = (e) => {
        e.preventDefault();

        this.setState({
            previewModalOpen: !this.state.previewModalOpen
        });
    }

    clickHandler = (e, id) => {
        if (this.state.activeId === id) {
            this.setState({
                activeId: "",
                contentHeight: 0
            });
        } else {
            const trigger = e.currentTarget;
            const content = trigger.nextSibling;
            const inner = content.children[0];
            const height = inner.offsetHeight;

            const that = this

            setTimeout(() => {
                scrollToElement(`#cast-${id}`, {
                    duration: 0,
                    offset: -90
                });
                if (document.getElementById("preview-schedule") !== null) {
                    document.getElementById("preview-schedule").addEventListener("click", function (event) {
                        that.togglePreviewModal(event)
                    });
                }
                if (document.getElementById("signup-button-faq") !== null) {
                    document.getElementById("signup-button-faq").addEventListener("click", function (event) {
                        that.onClick(event)
                    });
                }
            }, 100)

            this.setState({
                activeId: id,
                contentHeight: height
            });
        }

    };

    onClick = (e, resource) => {
        if (typeof window !== 'undefined') {
            window.did.launchNewsletters('frozen')
        }
    }

    render() {
        const faqs = this.props.data.allFaqsJson.edges.map(({node}, i) => {
            return <FaqItem
                key={i}
                id={`faq-${i}`}
                data={node}
                clickHandler={this.clickHandler}
                classNames={this.props.classNames}
                activeId={this.state.activeId}
                contentHeight={this.state.activeId === `faq-${i}` ? this.state.contentHeight : 0}
                activeClass={this.state.activeId === `faq-${i}` ? 'active' : ''}
            />
        })

        const mobileFaqsJson = this.props.data.allMobileFaqsJson.edges.map(({node}, i) => {
            return <FaqItem
                key={i}
                id={`mobileFaq-${i}`}
                data={node}
                clickHandler={this.clickHandler}
                classNames={this.props.classNames}
                activeId={this.state.activeId}
                contentHeight={this.state.activeId === `mobileFaq-${i}` ? this.state.contentHeight : 0}
                activeClass={this.state.activeId === `mobileFaq-${i}` ? 'active' : ''}
            />
        })

        return (
            <Layout pageName="faqs" slug="faqs" title="FAQs"
                    description="Find out performance times, ticket prices, access and other information for Frozen the Musical at London’s Theatre Royal Drury Lane from August 2021">
                <TopBar title="Frequently Asked Questions"/>

                <FaqContent>
                    <HeadingSpecial headingTag="h1"
                                    title="Frequently Asked Questions"/>
                        <Container fluid={true}>
                            <Container>
                                {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                                    <CustomCollapse>
                                        <Row className="mx-auto d-flex">
                                            {faqs}
                                        </Row>
                                        {/* <Row className="mx-auto d-flex d-lg-none">
                                            {mobileFaqsJson}
                                        </Row> */}
                                    </CustomCollapse>

                                    {/*<h3 id="close-to-frozen" className="mt-5">Introducing the Close to FROZEN App</h3>*/}
                                    {/*<Row className="mx-auto">*/}
                                    {/*    <Col xs={12} lg={6}>*/}
                                    {/*        <Box>*/}
                                    {/*            <h4>Close to FROZEN app</h4>*/}

                                    {/*            <GatsbyImage image={this.props.data.CloseApp.childImageSharp.gatsbyImageData}*/}
                                    {/*                alt="Close to FROZEN app"*/}
                                    {/*                title="Close to FROZEN app"*/}
                                    {/*                className="img-fluid d-block close-to-frozen"*/}
                                    {/*            />*/}
                                    {/*        </Box>*/}
                                    {/*    </Col>*/}
                                    {/*    <Col xs={12} lg={6}>*/}
                                    {/*        <Box>*/}
                                    {/*            <h4>What is the Close to FROZEN app?</h4>*/}

                                    {/*            <p>*/}
                                    {/*                The Close to FROZEN app gives you the opportunity to enhance your*/}
                                    {/*                journey to FROZEN from the moment you purchase tickets, by sharing*/}
                                    {/*                exclusive content and news as the show comes to life in London. It*/}
                                    {/*                also offers helpful information to make your experience as smooth*/}
                                    {/*                and easy as possible.*/}
                                    {/*            </p>*/}
                                    {/*        </Box>*/}

                                    {/*        <Box>*/}
                                    {/*            <h4>Who is it for?</h4>*/}

                                    {/*            <p>*/}
                                    {/*                Close to FROZEN is for everyone who has a ticket to see FROZEN the*/}
                                    {/*                Musical in Theatre Royal Drury Lane. The ticket buyer can access*/}
                                    {/*                their Close to FROZEN journey first, and then invite their FROZEN*/}
                                    {/*                guests to join them!*/}
                                    {/*            </p>*/}
                                    {/*        </Box>*/}
                                    {/*        <Box>*/}
                                    {/*            <h4>How do I get it?</h4>*/}

                                    {/*            <p>You will be prompted to join as you are booking your tickets, just*/}
                                    {/*                after you’ve entered your payment details. If you opt in at that*/}
                                    {/*                point you’ll get a text to invite you to download the app and start*/}
                                    {/*                your journey!</p>*/}
                                    {/*            <p>Or, after you have purchased your tickets to Frozen the Musical,*/}
                                    {/*                simply look out for your invitation to join the Close to FROZEN app*/}
                                    {/*                in your ticket confirmation email. Once you’ve created your*/}
                                    {/*                exclusive account, you can invite your friends to join – simple as*/}
                                    {/*                that!</p>*/}
                                    {/*        </Box>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                {/*</ScrollAnimation>*/}
                            </Container>
                        </Container>
                </FaqContent>
                <PreviewModal toggleModal={this.togglePreviewModal} modalOpen={this.state.previewModalOpen}/>

            </Layout>
        )
    }
}

export default Query

class FaqItem extends Component {
    render() {
        return (
            <Col xs={12} lg={6}>
                <Box faqs>
                    <div
                        className={`customCollapse__item ${this.props.activeClass}`}
                        style={{paddingBottom: this.props.contentHeight + "px"}}>
                        <div
                            id={`cast-${this.props.id}`}
                            className="customCollapse__item-trigger"
                            onClick={(e) => this.props.clickHandler(e, this.props.id)}>
                            <div className="customCollapse__wrapper">
                                <div className="customCollapse__copy-wrapper">
                                    <h4 className="customCollapse--title">{this.props.data.question}</h4>
                                </div>
                            </div>
                            <div className="arrowDown"/>
                        </div>
                        <div
                            className="customCollapse__item-wrapper"
                            style={{height: this.props.contentHeight + "px"}}>
                            <div className="customCollapse__item-wrapper-inner">
                                <div className="narrow">
                                    <div className="customCollapse__item-content"
                                         dangerouslySetInnerHTML={{__html: this.props.data.answerHTML}}/>
                                         {this.props.data.galaPro && (
                                            <>
                                            <p><strong>GalaPro&nbsp;</strong>is now available at all performances of Frozen the Musical &ndash; a revolutionary app for accessibility, which enhances your experience at live performances. You can use the app to enjoy the show with subtitles, audio description and closed captioning. All services are provided in real-time. Click&nbsp;<a href="https://lwtheatres.co.uk/gala-pro/" target="_blank" rel="noopener noreferrer">here</a>&nbsp;to find out more.</p>
                                            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                            <StaticImage style={{maxWidth: '100px'}} src="../images/AccessPage/1.png" alt="" />
                                            <StaticImage style={{maxWidth: '50px'}} src="../images/AccessPage/2.png" alt="" />
                                            <StaticImage style={{maxWidth: '50px'}} src="../images/AccessPage/3.png" alt="" />
                                            </div>
                                            </>
                                         )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Col>
        )
    }
}